<template>
  <v-container>
    <p class="display-1 text--primary">
      <v-btn class="mr-5 primary" text icon small dark @click="$router.go(-1)"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >Avaliação de Campanha
    </p>
    <v-card>
      <v-row>
        <v-col>
          <Resumo :status="campanha.id_status" :onEdite="false" />
        </v-col>
      </v-row>

      <v-col v-if="Object.keys(campanha).length">
        <v-textarea
          v-model="observacao"
          outlined
          label="Observação"
        ></v-textarea>
        <v-row class="ml-1" v-can-access="22">
          <v-btn
            color="green darken-1 white--text"
            @click="saveCampanha('1')"
            :disabled="campanha.id_status == '1'"
          >
            Aprovar
          </v-btn>
          <v-btn
            class="ml-2"
            color="error darken-1 white--text"
            @click="saveCampanha('3')"
            :disabled="campanha.id_status == '3'"
          >
            Reprovar
          </v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import Resumo from "@/components/campanha/cadastro/resumo/ResumoCadastroCampanha";
import campanhaService from "@/services/campanha";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AvaliacaoCampanha",

  components: {
    Resumo
  },

  data() {
    return {
      campanha: [],
      dialog: false,
      observacao: "",
      dialogConfig: {
        "1": {
          type: "approve",
          title: "Aprovação",
          text: "Deseja aprovar esta Campanha?"
        },
        "3": {
          type: "reprove",
          title: "Reprovação",
          text: "Deseja reprovar esta Campanha?"
        }
      },
      customSuccessMsg: {
        "1": "Campanhava Aprovada",
        "3": "Campanha Reprovada"
      }
    };
  },

  watch: {
    campanha() {
      this.observacao = this.campanha?.observacao;
    }
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha",
      getUser: "getUser"
    })
  },

  mounted() {
    this.fetchView("Avaliação de Campanha");
    Promise.all([
      this.fetchIndicadores(),
      this.fetchIndicesCrescimento(),
      this.actionCampanha(this.$route.params.idCampanha),
      this.actionFuncoesPublicoAlvo({
        id_campanha: this.$route.params.idCampanha
      }),
      this.getMetasProgressivas(this.$route.params.idCampanha),
      this.fetchEmpresa()
    ]);
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView",
      fetchIndicadores: "campanha/fetchIndicadores",
      fetchIndicesCrescimento: "campanha/fetchIndicesCrescimento",
      actionCampanha: "campanha/actionCampanha",
      actionEmpresasParticipantes: "campanha/actionEmpresasParticipantes",
      actionFuncoesPublicoAlvo: "campanha/actionFuncoesPublicoAlvo",
      getMetasProgressivas: "campanha/fetchFaixasMetasProgressivas"
    }),

    async fetchEmpresa() {
      try {
        const { data } = await campanhaService.getAprovacaoCampanhas({
          id_campanha: this.$route.params.idCampanha
        });

        this.campanha = data.data[0];
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possível buscar a campanha"
        });
      }
    },

    async saveCampanha(id_status) {
      const { type, title, text } = this.dialogConfig?.[id_status];
      const { id } = this.getUser;
      try {
        await this.$root.dialog({
          type,
          title,
          text,
          confirmText: "Sim",
          rejectText: "Não"
        });

        this.campanha.id_aprovacao
          ? await campanhaService.AtualizaAprovacaoCampanha(
              this.campanha.id_aprovacao,
              id_status,
              id,
              this.observacao
            )
          : await campanhaService.aprovacaoCampanha(
              this.$route.params.idCampanha,
              id_status,
              this.observacao
            );

        this.$router.go(-1);

        this.$notify({
          type: "success",
          text: this.customSuccessMsg?.[id_status]
        });
        this.dialog = false;
      } catch (error) {
        if (error.response.status == 403) {
          this.$notify({
            type: "error",
            text: error.response.data.message
          });
        } else {
          this.$notify({
            type: "error",
            text: "Não foi possível avaliar a campanha"
          });
        }
        this.dialog = false;
      }
    }
  }
};
</script>
